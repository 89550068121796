import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import './Home.scss';
import homeData from '../../assets/json/home-data.json';
import { LANGUAGE } from '../../constants/language.const';
import { useCustomNavigate } from '../../hooks/useCustomNavigate';
import { useGetImagesFolder } from '../../hooks/useGetImagesFolder';
import { SerialNumber, useSerialNumber } from '../../hooks/useSerialNumber';
import { SYSTEM_DEVICE, useSystemDevice } from '../../hooks/useSystemDevice';

interface HomeDataItem {
  defaultVisibility: boolean;
  image: string;
  page: string;
  title: string;
  vahaOnly?: boolean;
  visibility?: Record<string, boolean>;
  preventUppercasing?: boolean;
  showWideText?: boolean;
}

const initHomeData = (
  systemDevice: SYSTEM_DEVICE,
  serialNumber: SerialNumber,
) => {
  const timestampNow = Date.now();
  const modules = homeData[serialNumber] as HomeDataItem[];
  return modules.filter((module) => {
    if (module.vahaOnly && systemDevice !== SYSTEM_DEVICE.VAHA) {
      return false;
    }
    const visibilityConfig = module.visibility ?? {};
    const visibilityDates = Object.keys(visibilityConfig).sort(
      (dateStr1, dateStr2) => {
        return parseTimestamp(dateStr2) - parseTimestamp(dateStr1);
      },
    );
    const matchedDate = visibilityDates.find(
      (dateString) => timestampNow >= parseTimestamp(dateString),
    );
    return matchedDate
      ? visibilityConfig[matchedDate]
      : module.defaultVisibility;
  });
};

function parseTimestamp(dateString: string) {
  return new Date(dateString).valueOf();
}

export const BASE_HOME_PATH = '';

function Home() {
  const scope = 'Home';
  const systemDevice = useSystemDevice();
  const { t, i18n } = useTranslation();
  const customNavigate = useCustomNavigate();
  const imagesFolder = useGetImagesFolder();
  const serialNumber = useSerialNumber();
  const [homeData] = useState(initHomeData(systemDevice, serialNumber));
  const homeItemsCount = homeData.length;
  const shouldExpandedLastItem = homeData.length % 2 === 1;

  const handleTileClick = (page: string) => {
    if (page === 'faq') {
      const externalUrl = t('faq_external_url');
      if (
        externalUrl &&
        systemDevice !== SYSTEM_DEVICE.VAHA &&
        i18n.language === LANGUAGE.DE
      ) {
        window.open(externalUrl, '_blank', 'noreferrer');
        return;
      }
    }
    customNavigate({ page });
  };

  return (
    <div className={`${scope} ${scope}--${systemDevice}`}>
      <h1 className={scope + '-heading'}>{t('charging_lounge')}</h1>
      <div className={scope + '-grid'}>
        {homeData.map((homeItem, index) => {
          return (
            <button
              type="button"
              data-page-name={homeItem.page}
              key={homeItem.title}
              onClick={() => handleTileClick(homeItem.page)}
              className={`${scope}-gridCell ${
                shouldExpandedLastItem && index === homeItemsCount - 1
                  ? `${scope}-fullWidth`
                  : ''
              }`}
            >
              <img
                src={`${imagesFolder}/${homeItem.image}`}
                className={`${scope}-gridCellImage`}
                alt={homeItem.title}
              />
              <h2
                className={`${
                  homeItem.showWideText
                    ? `${scope}-gridCellTitle--wideText`
                    : `${scope}-gridCellTitle`
                } ${
                  homeItem.preventUppercasing
                    ? `${scope}-withoutTextTransform`
                    : ''
                }`}
              >
                {t(homeItem.title).replace('& ', '&\n')}
              </h2>
            </button>
          );
        })}
      </div>
    </div>
  );
}

export default Home;
